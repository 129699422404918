<template>
  <section id="about" class="about">
    <div class="about__cta" data-aos="zoom-out-down">
      <h1
        class="about__cta-header --cta-header"
      >
        What We Do
      </h1>
      <span class="about__cta-sub-header" style="font-weight: 400"
        >Performance marketing.<br />Modern tech.</span
      >
    </div>

    <div class="about__overview" data-aos="fade-in">
      <div class="about__overview-photo-container">
        <!--<img
          :src="coverPhoto"
          alt="Jumpfeed cover photo"
          class="about__overview-photo"
        />-->
        <picture>
          <source :srcset="coverPhotoWEBP" type="image/webp" />
          <source :srcset="coverPhoto" type="image/png" />
          <img :src="coverPhoto" class="about__overview-photo" />
        </picture>
      </div>
      <div class="about__overview-text-container">
        <p
          class="about__overview-text company-about --important"
          data-aos="zoom-out-up"
        >
          Jumpfeed is partnered with top tier media platforms and uses a
          multichannel network in order to serve targeted listings to active
          shoppers in automotive, real estate, jobs and retail. With a strong
          foundation of integrated data, insights driven analytics, and data
          science - we've developed the technology to detect in-market consumers
          and serve them the most relevant products when they are likely to
          convert.
        </p>
      </div>
      <div id="overview_stats_panel" class="about__overview-stats-panel">
        <div
          v-for="(statBlurb, i) in statBlurbs"
          :key="i + '_statBlurb'"
          class="stat-container"
          data-aos="fade-in"
          :data-aos-delay="i * 150"
          :data-aos-id="'stat-blurb-' + i"
        >
          <span class="stat-value">
            {{ statBlurb.value.toFixed(2) + statBlurb.valueUnit }}
          </span>
          <span class="stat-header">
            {{ statBlurb.label }}
          </span>
        </div>
      </div>
    </div>

    <div class="about__main">
      <div class="about__main-content" data-aos="fade-in">
        <h3 class="about__main-content-header">
          How We Work
        </h3>
        <div class="about__main-content-sections">
          <div class="about__main-content-section --left">
            <h5 class="--text-uppercase --color-accent">
              Test, Learn & Iterate
            </h5>
            <p>
              Our mission is to continuously push the boundaries of media
              performance and ROAS (Return On Ad Spend) to gain your business a
              competitive advantage. We have a philosophy built on incremental
              improvement through constant testing - there is always more to
              gain.
              <br />
              <br />
              In an ever-shifting digital landscape, Jumpfeed is uniquely
              positioned to develop, test and iterate using best-in-class
              technologies and architectures. With industry-leading channel
              automation and over a decade of performance marketing experience -
              we have the capability to tackle all of your most pressing
              challenges.
            </p>
          </div>
          <div class="about__main-content-section --right">
            <h5 class="--text-uppercase --color-accent">
              Execution Is Everything
            </h5>
            <p>
              We don’t wait, we do. At Jumpfeed, execution and innovation is
              what drives us - we strive to work more efficiently to save our
              clients time and money. Our culture is one that emphasizes
              building relationships, putting in the time, and working hard to
              help ensure that we are always learning and able to adapt.
              <br />
              <br />
              Think you have what it takes to work at Jumpfeed? We’re always on
              the hunt for motivated individuals looking to make a mark.
              <a href="mailto:careers@jumpfeed.com" style="color: #fff"
                >Contact Us</a
              >.
            </p>
          </div>
        </div>
      </div>
    </div>

    <div class="about__team" data-aos="fade-in">
      <a
        v-for="(teamMember, i) in teamMembers"
        :key="i + '_teamMember'"
        class="about__team-member"
        :href="teamMember.linkout"
        target="_blank"
      >
        <!-- TODO: On hover, change to an Alternate photo -->
        <!--<img :src="teamMember.image" :alt="'Image of ' + teamMember.name" />-->
        <picture>
          <source :srcset="teamMember.imageWEBP" type="image/webp" />
          <source :srcset="teamMember.image" type="image/png" />
          <img :src="teamMember.image" />
        </picture>
        <p>
          <span
            v-html="teamMember.name"
            class="--text-uppercase"
            style="font-weight: 500"
          />
          <i
            v-if="teamMember.showLinkedInIcon"
            class="fab fa-linkedin"
            style="margin-left: 10px"
          ></i>
          <br />
          <span v-html="teamMember.role" />
        </p>
      </a>
    </div>
  </section>
</template>

<script>
import CoverPhoto from "@/assets/images/About-Cover-Photo.png";
import CoverPhotoWEBP from "@/assets/images/About-Cover-Photo.webp";
import AustinMallia from "@/assets/images/team/Austin-Mallia.png";
import AustinMalliaWEBP from "@/assets/images/team/Austin-Mallia.webp";
import ArenWells from "@/assets/images/team/Aren-Wells.png";
import ArenWellsWEBP from "@/assets/images/team/Aren-Wells.webp";
import MeetTheTeam from "@/assets/images/team/Meet-The-Team.png";
import MeetTheTeamWEBP from "@/assets/images/team/Meet-The-Team.webp";

export default {
  name: "About",
  metaInfo: {
    titleTemplate: "%s | About",
    link: [{ rel: "canonical", href: "https://www.jumpfeed.com/about" }]
  },
  data() {
    return {
      coverPhoto: CoverPhoto,
      coverPhotoWEBP: CoverPhotoWEBP,
      teamMembers: [
        {
          name: "Austin Mallia",
          role: "CEO & Co-Founder",
          image: AustinMallia,
          imageWEBP: AustinMalliaWEBP,
          linkout: "https://www.linkedin.com/in/austinmallia/",
          showLinkedInIcon: true
        },
        {
          name: "Aren Wells",
          role: "CTO & Co-Founder",
          image: ArenWells,
          imageWEBP: ArenWellsWEBP,
          linkout: "https://www.linkedin.com/in/aren-wells-834121141/",
          showLinkedInIcon: true
        },
        {
          name: "Wanna Join The Team?",
          role:
            '<a href="mailto:careers@jumpfeed.com" style="color: #000">Contact Us</a>',
          image: MeetTheTeam,
          imageWEBP: MeetTheTeamWEBP,
          linkout: "mailto:careers@jumpfeed.com",
          showLinkedInIcon: false
        }
      ],
      statBlurbs: [
        {
          label: "Active shoppers in network",
          value: 0.0,
          endValue: 5.35,
          valueUnit: "M+",
          valueAnimationTriggered: false,
          valueAnimationInterval: null
        },
        {
          label: "Products served to in-market consumers",
          value: 0.0,
          endValue: 97.12,
          valueUnit: "M+",
          valueAnimationTriggered: false,
          valueAnimationInterval: null
        },
        {
          label: "Shoppers converted into buyers, leads & inquiries",
          value: 0.0,
          endValue: 4.96,
          valueUnit: "M+",
          valueAnimationTriggered: false,
          valueAnimationInterval: null
        }
      ]
    };
  },
  mounted() {
    document.addEventListener("scroll", this.handleAboutScroll);
  },
  destroyed() {
    document.removeEventListener("scroll", this.handleAboutScroll);
  },
  methods: {
    handleAboutScroll() {
      if (
        this.isScrolledIntoView(document.getElementById("overview_stats_panel"))
      ) {
        let vm = this;
        setTimeout(function() {
          vm.statBlurbs.forEach(function(statBlurb, i) {
            if (!vm.statBlurbs[i].valueAnimationTriggered) {
              vm.statBlurbs[i].valueAnimationTriggered = true;
              vm.statBlurbs[i].valueAnimationInterval = setInterval(function() {
                let incrementAmount = vm.statBlurbs[i].endValue / 100;
                if (
                  vm.statBlurbs[i].value <
                  vm.statBlurbs[i].endValue - incrementAmount
                ) {
                  vm.statBlurbs[i].value += incrementAmount;
                } else {
                  vm.statBlurbs[i].value = vm.statBlurbs[i].endValue;
                  clearInterval(vm.statBlurbs[i].valueAnimationInterval);
                }
              }, 20);
            }
          });
        }, 150);
      }
    },
    isScrolledIntoView(el) {
      let rect = el.getBoundingClientRect();
      let elemTop = rect.top;
      let elemBottom = rect.bottom;

      return elemTop < window.innerHeight && elemBottom >= 0;
    }
  }
};
</script>

<style scoped lang="scss">
.about {
  margin-top: $header-size;
  padding-top: $gap-very-large;

  .about__cta {
    @include containWidth();
    @include defaultContainerPadding();

    .about__cta-sub-header {
      font-size: 2.66em;
      display: block;
    }
  }

  .about__overview {
    @include displayFlex(column, space-between, flex-start);
    //@include containWidth();
    position: relative;
    width: 100%;
    //width: calc(100% - #{2 * $gap-large});
    //margin: $gap-large auto 4.5rem auto;
    padding-bottom: $gap-large;

    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      display: inline-block;
      background: linear-gradient(
        to bottom,
        rgba(15, 14, 15, 0),
        rgb(15, 14, 15)
      );
    }

    @include screenSmall {
      width: 100%;
      margin: 0;
      margin-bottom: 3.5rem;
    }

    @include screenMedium {
      @include displayFlex(column, flex-start, flex-start);
      min-height: auto;
      margin-bottom: $gap-large;
      padding-bottom: 0;
    }

    .about__overview-photo-container {
      &:after {
        content: "";
        display: block;
        position: absolute;
        left: 0;
        right: 0;
        top: 0;
        bottom: 0;
        background-image: radial-gradient($primary 33%, transparent 33%);
        background-size: 3px 3px;

        @include screenMedium {
          background-image: none;
        }
      }

      .about__overview-photo {
        position: absolute;
        top: 0;
        width: 100%;
        filter: opacity(0.55) grayscale(1);
        min-height: 100px;

        @include screenMedium {
          position: relative;
          filter: opacity(0.75) grayscale(1);
        }
      }
    }

    .about__overview-text-container {
      @include containWidth();
      @include defaultContainerPadding();
      position: relative;
      z-index: 1;
      margin-top: $gap-large;

      @include screenMedium {
        position: relative;
        margin-bottom: 0;
        margin-top: 0;
      }

      .about__overview-text {
        width: min(100%, 1024px);
        font-size: 1.25em;
        font-weight: 400;
        line-height: 1.7em;
        //text-shadow: 1px 1px $primary;
      }
    }

    .about__overview-stats-panel {
      @include displayFlex(row, space-between, flex-start);
      @include defaultContainerPadding();
      @include containWidth();
      width: 100%;
      z-index: 1;
      margin-top: $gap-large;

      @include screenMedium {
        width: 100%;
        position: relative;
        @include displayFlex(column, flex-start, center);
        text-align: center;
        margin-top: 0;
      }

      .stat-container {
        padding: 0 $gap-large;
        max-width: 25%;

        &:first-of-type {
          padding-left: 0;
        }
        &:last-of-type {
          padding-right: 0;
        }

        @include screenMedium {
          text-align: center;
          font-size: 1.2em;
          padding: $gap-standard 0;
          max-width: 100%;
        }

        .stat-value {
          margin-bottom: $gap-very-small;
          color: $accent;
          text-transform: uppercase;
          font-size: 2.66em;
          display: block;
        }
        .stat-header {
          font-weight: 300;
          font-size: 1em;
          display: block;
        }
      }
    }
  }

  .about__main {
    position: relative;
    background: $primary-light;

    @include screenMedium {
      padding-top: $gap-very-large;
      padding-bottom: $gap-very-large;
    }

    .about__main-content {
      @include containWidth();
      @include defaultContainerPadding();

      .about__main-content-header {
        text-transform: uppercase;
        font-weight: 400;
      }

      .about__main-content-sections {
        @include displayFlex(row, space-between, flex-start);

        @include screenMedium {
          @include displayFlex(column, flex-start, flex-start);
        }

        .about__main-content-section {
          width: calc(50% - #{$gap-standard * 2});

          &.--left {
            padding-right: $gap-standard;
          }
          &.--right {
            padding-left: $gap-standard;
          }

          @include screenMedium {
            width: 100%;

            &.--left {
              padding: 0;
              margin-top: $gap-standard;
            }

            &.--right {
              padding: 0;
              margin-top: $gap-very-large;
            }
          }
        }
      }
    }
  }

  .about__team {
    @include containWidth();

    @include displayFlex(row, center, center);
    //background: $base;
    color: $primary;

    @include screenMedium {
      @include displayFlex(column, flex-start, flex-start);
    }

    .about__team-member {
      width: 33.333333333%;
      background: $base;
      color: $primary;
      text-decoration: none;
      @include transition(background);

      @include screenMedium {
        width: 100%;
      }

      &:hover {
        background: $accent;

        img {
          filter: brightness(1.05);
        }
      }

      img {
        width: 100%;
        filter: brightness(0.9);

        @include transition(all);
      }

      p {
        padding: $gap-large;
      }
    }
  }
}
</style>
